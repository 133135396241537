































import { Component, Vue } from 'vue-property-decorator'
import RightInformation from '@/components/RightInformation.vue'
import { getfunctionPriceList, getproductLogList } from '@/api/product'
@Component({
  components: { RightInformation }
})
export default class Log extends Vue {
  functionPriceList = []
  productLogList = []
  activeName=''
  created() {
    getfunctionPriceList({ id: this.$route.query.key }).then((res) => {
      this.functionPriceList = res.data
      this.activeName =res.data[0].packageId
      getproductLogList({ id: res.data[0].packageId }).then((ress) => {
      this.productLogList = ress.data
    })
    })
  }
  handleChange(val: any) {
    this.productLogList = []
    getproductLogList({ id: val }).then((res) => {
      this.productLogList = res.data
    })
  }
}
